class CarouselTemplateGloRS extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('CarouselTemplateDefault before load');
	}

	afterLoad() {
		// console.log('CarouselTemplateDefault After load');
		this.$el = $(this);
		const isAuthorMode = this.$el.hasClass('author-mode');
		const slideContainer = this.$el.find('.bat-carousel-slides');

		// Convert values to bools and ints
		this.data.config.arrows = JSON.parse(this.data.config.arrows);
		this.data.config.autoplay = JSON.parse(this.data.config.autoplay);
		this.data.config.dots = JSON.parse(this.data.config.dots);
		this.data.config.infinite = JSON.parse(this.data.config.infinite);
		this.data.config.speed = this.data.config.speed
			? parseInt(this.data.config.speed, 10)
			: 300;

		// hide arrows if false
		if (!this.data.config.arrows) {
			this.$el.find('.slick-arrow').hide();
		} else {
			if(!this.data.leftArrowIcon.iconId && !this.data.leftArrowIcon.iconFile) {
				this.$el.find('.slick-prev > .bat-icon').addClass('icon-arrow-left');
			}
			if(!this.data.rightArrowIcon.iconId && !this.data.rightArrowIcon.iconFile) {
				this.$el.find('.slick-next > .bat-icon').addClass('icon-arrow-right');
			}
		}

		// No re-render updates
		$.map(this.$el.find('.bat'), (el) => {
			$(el).addClass('no-update');
		});

		const slickConfig = $.extend(
			{
				prevArrow: this.$el.find('.slick-prev'),
				nextArrow: this.$el.find('.slick-next'),
				rows: 0,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: false,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true,
						},
					},
				],
			},
			this.data.config
		);

		if (!isAuthorMode) {
			$(slideContainer).on('init', () => {
				$(slideContainer).addClass('loaded');
			});
			// $(slideContainer).addClass('loaded');
			setTimeout(() => {
				$(slideContainer).slick(slickConfig);
			}, 500);
		}
	}

	beforeUpdate() {
		// console.log('CarouselTemplateDefault before update');
	}

	afterUpdate() {
		// console.log('CarouselTemplateDefault after update');
	}

	unload() {
		// console.log('CarouselTemplateDefault after unload');
	}
}

!customElements.get('bat-carousel-glors') &&
	customElements.define('bat-carousel-glors', CarouselTemplateGloRS);
